import chroma from "chroma-js";
import colors from "vuetify/lib/util/colors";

function shuffle(array, seed) {
  // <-- ADDED ARGUMENT
  var m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed; // <-- ADDED LINE
  }

  return array;
}

function random(seed) {
  var x = Math.sin(seed++) * 10000; 
  return x - Math.floor(x);
}

const colorArray = Object.entries(colors)
  .filter(([key, val]) => key != "shades")
  .map(([, val]) =>
    chroma
      .scale([val.darken4, val.darken2, val.base, val.lighten2])
      .correctLightness()
      .colors()
  )
  .flat();

export default shuffle(colorArray, 7);
